import React, { useState, useEffect, useRef, useContext } from "react";
import { TextField, Typography, Box } from "@mui/material";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import './index.sass';
import FunctionStore from "../../store/functionstore";
import AccountStore from '../../store/accountStore';
import Preloader from "../preloader";
import { UALContext } from "ual-reactjs-renderer";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

export default function DivWithSpans(props) {
    const [config, setConfig] = useState(null);
    const [inputValue, setInputValue] = useState("");
    const [uniqueValues, setUniqueValues] = useState([]);
    const [showWallets, setShowWallets] = useState(false);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [val, setVal] = useState([]);
    const [day, setDays] = useState([]);
    const [bulkamt, setBulkAmt] = useState(0);
    const [bulkday, setBulkday] = useState(props?.config?.min_days ?? 1);
    const [bulkfees, setBulkFees] = useState(0);
    const [feeAmount, setFees] = useState([]);
    const [totalNewFees, setTotalNewFees] = useState(0);
    var errorTimeout = useRef(null);
    const ual = useContext(UALContext);

    useEffect(() => {
        const calculateTotalFees = () => {
            const sum = feeAmount.reduce((total, value) => total + value, 0);
            setTotalNewFees(sum);
        };

        calculateTotalFees();
    }, [feeAmount]);

    useEffect(() => {
        if (props.config.length != 0) {
            setConfig(props.config);
            setBulkday(props.config?.min_days ?? 1);
        }
    }, [props.config]);

    useEffect(() => {
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);

    function handleInputChange(event) {
        setInputValue(event.target.value.toLowerCase());
    }

    const login = () => {
        ual.logout();
        ual.showModal();
    }

    async function handleInputBlur() {
        const inputArray = inputValue.split(",")
            .map((value) => value.trim())
            .filter(Boolean);
        const uniqueArray = [...new Set(inputArray)];
        if(uniqueArray.length > 25) {
            FunctionStore.handleError("Please Enter less than 25 Wallets at a single time !"); 
            return;
        }
        else{
            const filteredArray = uniqueArray.filter((value) => !uniqueValues.includes(value));
            if (filteredArray.length > 0){
                setUniqueValues((prevValues) => [...prevValues, ...filteredArray]);
            }
        }
    }

    async function handleButtonClick() {
        var bool_check = await filterUniqueValues();
        if (bool_check) setShowWallets(true);
        else FunctionStore.handleError("No Wallets Found to Display !");
        setInputValue('');
    }

    if (config == null)
        return <Preloader />

    function delay(ms) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }

    async function checkIfWalletExistsInTableData(wallet) {
        return tableData.some((data) => data.account === wallet);
    }

    async function filterUniqueValues() {
        FunctionStore.handleError("Fetching Data... Please Wait for a few seconds");
        var alreadyStakedWallets = [];
        const filteredValues = [];
        const batch_wallets = await Promise.all(uniqueValues.map(async (value) => {
            const walletExists = await checkIfWalletExistsInTableData(value);
            return walletExists ? null : value;
        }));
        const filterVal_Batch = batch_wallets.filter((value) => value !== null);
        console.log(filterVal_Batch)
        for (let i = 0; i < filterVal_Batch.length; i += 12) {
            const batch = filterVal_Batch.slice(i, i + 12);
            const batchResults = await processBatch(batch, alreadyStakedWallets);
            filteredValues.push(...batchResults);
            await delay(500);
        }
        const filterVal = filteredValues.filter((value) => value != null);
        if (alreadyStakedWallets.length > 0) {
            FunctionStore.handleError(`For ${alreadyStakedWallets.map((e) => e + ' ').join(',')} WAX Already Staked`);
        }
        if (filterVal.length > 0) {
            setUniqueValues(filterVal);
            await getTableData(filterVal);
            return true;
        } else {
            return false;
        }
    }

    async function processBatch(batch, alreadyStakedWallets) {
        const promises = batch.map(async (value) => {
            const accountExists = await FunctionStore.checkAccountExists(value);
            const { alreadyStaked, userData } = await FunctionStore.checkAlreadyStaked(value);
            if (alreadyStaked) alreadyStakedWallets.push(value);
            return accountExists && !alreadyStaked ? value : null;
        });
        return Promise.all(promises);
    }


    function clearList() {
        setShowWallets(false);
        setInputValue([]);
        setUniqueValues([]);
        setTableData([]);
        setVal([]);
        setDays([]);
        setFees([]);
        setTotalNewFees(0);
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleStake = async (event, index) => {
        const { value } = event.target;
        if (value < 0)
            FunctionStore.handleError(`Value cannot be negative`);
        setVal((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = value;
            return newVal;
        });
        var fees = await getAmount(value, day[index] ?? config?.min_days ?? 1);
        setFees((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = fees;
            return newVal;
        });
    };

    const handleInputChangeDay = async (event, index) => {
        const { value } = event.target;
        clearTimeout(errorTimeout.current);
        errorTimeout.current = setTimeout(() => {
            const minDays = parseFloat(config?.min_days) || 1;
            const maxDays = parseFloat(config?.max_days) || 45;

            if (value < minDays || value > maxDays) {
                FunctionStore.handleError(`Cannot be less than ${minDays} or greater than ${maxDays} days`);
            }
        }, 3000);

        setDays((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = value;
            return newVal;
        });

        var fees = await getAmount(val[index] ?? 0, value);
        setFees((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = fees;
            return newVal;
        });
    };


    const handleInputBulkDay = async (event) => {
        const { value } = event.target;
        clearTimeout(errorTimeout.current);
        errorTimeout.current = setTimeout(() => {
            const minDays = parseFloat(config?.min_days) || 1;
            const maxDays = parseFloat(config?.max_days) || 45;

            if (value < minDays || value > maxDays) {
                FunctionStore.handleError(`Cannot be less than ${minDays} or greater than ${maxDays} days`);
            }
        }, 2000);

        setBulkday(value);
        var fees = await getAmount(bulkamt, value);
        setBulkFees(fees * uniqueValues.length);
    };

    const handleInputBulkAmt = async (event, index) => {
        const { value } = event.target;
        if (value < 0)
            FunctionStore.handleError(`Value cannot be negative`);
        setBulkAmt(value);
        var fees = await getAmount(value, bulkday);
        setBulkFees(fees * uniqueValues.length);
    }

    const handleRemoveRow = async (index) => {
        const newTableData = [...tableData];
        newTableData.splice(index, 1);
        setTableData(newTableData);

        const newUniqueValues = [...uniqueValues];
        newUniqueValues.splice(index, 1);
        setUniqueValues(newUniqueValues);

        const newInputValue = inputValue
            .split(",")
            .filter((val) => val != newUniqueValues[index])
            .join(",");
        setInputValue(newInputValue);

        setVal(([0]));
        setDays(([1]));
        setFees(([0]));
    }

    const getAmount = async (value, day_temp) => {
        return parseFloat(((config?.fee_rate * value) / 100) * day_temp);
    }


    const getTableData = async (values) => {
        const cpuPromises = values.map((data) => FunctionStore.getAccountCpuUsage(data));
        const cpuResults = await Promise.all(cpuPromises);
        const tableData = await Promise.all(
            values.map(async (data, index) => {
                return createData(
                    data,
                    cpuResults[index].cpuMax,
                    cpuResults[index].cpuUsed
                );
            })
        );
        setTableData((prevValues) => [...prevValues, ...tableData]);
        var fees = await getAmount(bulkamt, bulkday);
        setBulkFees(fees * uniqueValues.length);
    };

    async function createData(
        account: string,
        cpuMax: number,
        cpuUsed: number
    ) {
        return {
            account,
            cpuMax: cpuMax.toFixed(2),
            cpuUsed: cpuUsed.toFixed(2)
        };
    }

    const totalRows = tableData.length;
    const submit = async (type, arr_wallets) => {
        confirmAlert({
            title: 'Please confirm to continue',
            message: 'Given that the number of wallets exceeds 15, transactions will be sent in batches of 15',
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => await FunctionStore.stakeWax(type, arr_wallets)
                },
                {
                    label: 'No',
                    onClick: () => FunctionStore.handleError("Transaction Cancelled !")
                }
            ]
        });
    };

    return (
        <Box style={{ display: 'flex', flexDirection: 'column',marginBottom:'5vh' }}>
            <TextField
                placeholder="Enter wallet separated by commas (abc1.wam,abc2.wam) You can add up to 25 wallets at a time"
                variant="outlined"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={handleInputBlur}
                fullWidth
                margin="normal"
                style={{
                    width: "100%",
                    height: "auto",
                    border: "2px solid #56dcd7",
                    borderRadius: "1em",
                    marginTop: "2em",
                }}
                InputLabelProps={{ style: { color: "white", fontFamily: 'Roboto' } }}
                InputProps={{ style: { fontFamily: 'Roboto', color: "white", textTransform: 'lowercase' }, rows: 4 }}
                multiline
            />
            <div className="button-parent">
            <button
                className="button-div"
                onClick={handleButtonClick}
                style={{ margin: "1em 1em" }}
            >
                Add Wallets
            </button>
                <button className="button-div" onClick={clearList} style={{ margin: '1em 1em' }}>
                    Clear List
                </button>
            </div>

            {showWallets && (<>
                <TableContainer className="table" component={Paper}
                    style={{
                        backgroundColor: '#141414',
                        marginTop: '0.5em',
                        padding: '0.5em',
                        border: '2px solid #56dcd7'
                    }}>
                    <Table sx={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <TableRow
                                style={{
                                    borderTop: 'none',
                                    borderLeft: 'none',
                                    borderRight: 'none',
                                    borderBottom: '2px solid #56dcd7'
                                }}

                            >
                                <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                                    ACCOUNT
                                    <div style={{
                                        position: 'absolute',
                                        top: '25%',
                                        right: 0,
                                        bottom: 0,
                                        width: '10%',
                                        height: '50%',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRight: '2px solid #ffde59'
                                    }}></div>
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                                    CPU USAGE
                                    <div style={{
                                        position: 'absolute',
                                        top: '25%',
                                        right: 0,
                                        bottom: 0,
                                        width: '10%',
                                        height: '50%',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRight: '2px solid #ffde59'
                                    }}></div>
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                                    STAKE
                                    <div style={{
                                        position: 'absolute',
                                        top: '25%',
                                        right: 0,
                                        bottom: 0,
                                        width: '10%',
                                        height: '50%',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRight: '2px solid #ffde59'
                                    }}></div>
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                                    DAYS
                                    <div style={{
                                        position: 'absolute',
                                        top: '25%',
                                        right: 0,
                                        bottom: 0,
                                        width: '10%',
                                        height: '50%',
                                        borderTop: 'none',
                                        borderLeft: 'none',
                                        borderBottom: 'none',
                                        borderRight: '2px solid #ffde59'
                                    }}></div>
                                </TableCell>
                                <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                                    BUY
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                            </TableRow>
                            {tableData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
                                <TableRow key={row.account} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                    <TableCell component="th" scope="row" style={{
                                        color: 'white',
                                        fontFamily: 'Roboto',
                                        border: '1px solid rgba(255,255,255,0.5)'
                                    }}>
                                        {row.account}
                                    </TableCell>
                                    <TableCell align="center" style={{
                                        color: 'white',
                                        fontFamily: 'Roboto',
                                        border: '1px solid rgba(255,255,255,0.5)'
                                    }}>{`${parseFloat(row.cpuUsed).toFixed(2)}ms/${parseFloat(row.cpuMax).toFixed(2)}ms`}&nbsp;<span style={{ color: parseFloat((row.cpuUsed / row.cpuMax) * 100) >= 90 ? 'red' : 'white' }}>{`${parseFloat((row.cpuUsed / row.cpuMax) * 100).toFixed(2)}%`}</span></TableCell>
                                    <TableCell align="center" style={{
                                        color: 'white',
                                        fontFamily: 'Roboto',
                                        border: '1px solid rgba(255,255,255,0.5)',
                                        padding: '0.5em'
                                    }}>
                                        <div className='add-container' style={{ borderRadius: '1em' }}>
                                            <input
                                                className='inputclass'
                                                type="number"
                                                pattern="[0-9]*"
                                                value={val[index] ?? 0}
                                                onChange={(event) => handleStake(event, index)}
                                            />
                                            <span>WAX</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" style={{
                                        color: 'white',
                                        fontFamily: 'Roboto',
                                        border: '1px solid rgba(255,255,255,0.5)',
                                        padding: '0.5em'
                                    }}>
                                        <div className='add-container' style={{ borderRadius: '1em' }}>
                                            <input
                                                className='inputclass'
                                                type="number"
                                                pattern="[0-9]*"
                                                value={day[index] ?? config?.min_days ?? 1}
                                                onChange={(event) => handleInputChangeDay(event, index)}
                                            />
                                            <span>Days</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center" style={{
                                        color: 'white',
                                        fontFamily: 'Roboto',
                                        border: '1px solid rgba(255,255,255,0.5)',
                                        padding: '0 0 0 0'
                                    }}>
                                        <div className='add-container' style={{ backgroundColor: 'transparent', border: 'none', justifyContent: 'space-between' }}>
                                            <button className="buttonclass" style={{ backgroundColor: '#246AED', color: 'white' }}
                                                onClick={async () => {
                                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                                    feeAmount[index] ? await FunctionStore.stakeWax('new', [{
                                                        account: row.account,
                                                        quantity: feeAmount[index],
                                                        days: day[index] ?? config?.min_days ?? 1
                                                    }]) : FunctionStore.handleError('Invalid Request');
                                                }}>{`${parseFloat(feeAmount[index] ?? 0).toFixed(3)} WAX`}</button>
                                            <button style={{ backgroundColor: 'transparent', color: 'white', border: 'none', fontSize: '1.2em', border: '1px solid white' }} onClick={() => handleRemoveRow(index)}>X</button>
                                        </div>
                                    </TableCell>
                                </TableRow>
                            ))}
                            <TableRow>
                                <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none', color: 'white', fontFamily: 'Roboto', textAlign: 'center', fontSize: '1em', fontWeight: 'bold', textTransform: 'uppercase' }} colSpan={4}>
                                    Total (Fees) for all accounts
                                </TableCell>
                                <TableCell className="buttonclass" style={{ backgroundColor: '#246AED', color: 'white', border: 'none', padding: '1em', textAlign: 'center', fontWeight: 'bold' }}
                                    onClick={async () => {
                                        if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                        var arr_wallets = [];
                                        tableData.map((row, index) => {
                                            if (feeAmount[index] > 0)
                                                arr_wallets.push({
                                                    account: row.account,
                                                    quantity: feeAmount[index],
                                                    days: day[index] ?? config?.min_days ?? 1
                                                });
                                        });
                                        if (arr_wallets.length > 0) {
                                            arr_wallets.length > 15 ? submit('new', arr_wallets) : await FunctionStore.stakeWax('new', arr_wallets);
                                        }
                                        else
                                            FunctionStore.handleError('Invalid Request');
                                    }}>{`${totalNewFees.toPrecision(3)} WAX`}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[2, 5, 10, 25, 100]}
                        component="div"
                        count={totalRows}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        style={{
                            backgroundColor: 'rgba(255,255,255,.8)',
                            color: 'black',
                            borderRadius: '1em',
                            WebkitBackdropFilter: 'blur(5px)',
                            backdropFilter: 'blur(5px)',
                            width: '100%',
                            fontFamily: 'Roboto',
                            overflow: 'auto',
                            scrollBehavior: 'smooth',
                            scrollbarWidth: 'thin'
                        }}
                    />
                </TableContainer>
                <div className="bulk">
                    <span className="title3">Bulk Stake</span>
                    <div className="bulk-child">
                        <span className="waxFor">STAKE</span>
                        <input
                            className='inputclass'
                            type="number"
                            pattern="[0-9]*"
                            value={bulkamt}
                            style={{ color: 'white', borderBottom: '1px solid white' }}
                            onChange={(event) => handleInputBulkAmt(event)}
                        />
                        <span className="waxFor">WAX FOR</span>
                        <input
                            className='inputclass'
                            type="number"
                            pattern="[0-9]*"
                            value={bulkday ?? props?.config?.min_days ?? 1}
                            style={{ color: 'white', borderBottom: '1px solid white' }}
                            onChange={(event) => handleInputBulkDay(event)}
                        />
                        <span className="waxFor">DAYS</span>
                        <button className="buttonclass1" style={{ backgroundColor: '#246AED', color: 'white', width:'2em' }}
                            onClick={async () => {
                                if (AccountStore.accountAddress == null) {
                                    login();
                                    return;
                                }
                                var obj = [];
                                uniqueValues.map(data => {
                                    obj.push({
                                        account: data,
                                        quantity: bulkfees / uniqueValues.length,
                                        days: bulkday
                                    });
                                })
                                if (uniqueValues.length > 0) {
                                    uniqueValues.length > 15 ? submit('new', obj) : await FunctionStore.stakeWax('new', obj);
                                }
                                else
                                    FunctionStore.handleError('Invalid Request')
                            }}>{`${bulkfees.toFixed(3)} WAX`}</button>
                    </div>
                </div>
            </>
            )}
        </Box>
    );
}
