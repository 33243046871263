import { observer } from "mobx-react";
import { useState, useEffect, useRef, useContext } from "react";
import StoreContext from "../../store/rootstore";
import ContentBox from "../../components/contentBox";
import Preloader from "../../components/preloader";
import "./index.sass";
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import Table from '../../components/tableui';
import MultipleTable from '../../components/tableui_multiple';
import Mytimer from '../../components/timer/index';
import { UALContext } from "ual-reactjs-renderer";
import { config } from "react-transition-group";

function Menu() {
    const { AccountStore, FunctionStore } = StoreContext();
    const [multiple, setMultipleMode] = useState(false);
    const [data, setData] = useState(null);
    const [days, setDays] = useState(null);
    const [stake, setStake] = useState(0);
    const [customstake, setCustom] = useState(0);
    const [wallet, setWallet] = useState(AccountStore.getWallet() != null ? AccountStore.getWallet() : '');
    const [cpuMax, setCpuMax] = useState(0);
    const [cpuAvb, setCpuAvb] = useState(0);
    const [validAcc, setValid] = useState(false);
    const [userStaked, setUserStaked] = useState(null);
    const [alreadyStakedwax, setAlreadyStaked] = useState(false);
    const [addfees, setAddFees] = useState([0, 0, 0, 0]);
    const [customaddInput, setCustomAddInput] = useState(0);
    const [customAddFee, setCustomAddFee] = useState(0);
    const [renewfees, setRenewFees] = useState(0);
    const [renewfeesOrg, setRenewFeesOrg] = useState(0);
    const [timerVisible, setTimerVisible] = useState(true);
    const [expiryStamp, setExpiryStamp] = useState(0);
    const ual = useContext(UALContext);

    var searchTimeout = useRef(null);
    var errorTimeout = useRef(null);

    async function login() {
        ual.logout();
        ual.showModal();
    }

    const fetchData = async () => {
        var data = AccountStore.getUserData();
        setData(data);
        setDays(data?.config?.min_days);
        if (AccountStore.getWallet() != null) await performSearch(wallet);
    }
    useEffect(() => {
        if (!AccountStore.get_loading()) fetchData();
    }, [AccountStore.get_loading()]);

    useEffect(() => {
        return () => {
            clearTimeout(searchTimeout.current);
        };
    }, []);

    useEffect(() => {
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);

    useEffect(() => {
        setTimerVisible(false);
        setTimeout(() => {
            setTimerVisible(true);
        }, 100);
    }, [expiryStamp]);

    const getAmount = (amount) => {
        return ((data?.config?.fee_rate * amount) / 100) * days;
    }

    const getCustomAddFees = async (unstakeTime, value) => {
        const currentEpoch = Math.floor(Date.now() / 1000);
        let remainingTime = parseInt(unstakeTime) - currentEpoch;
        if (remainingTime < 12 * 3600) remainingTime = 12 * 3600;
        const calculateAddFee = (val) => {
            const fee = parseFloat(val) * ((parseFloat(data?.config?.fee_rate) / parseFloat(data?.config?.unstakeSeconds)) * remainingTime / 100);
            return parseFloat(fee).toFixed(4);
        };
        setCustomAddFee(parseFloat(calculateAddFee(value)));
        const interval = setInterval(() => {
            setCustomAddFee(parseFloat(calculateAddFee(value)));
        }, 300000);

        return () => {
            clearInterval(interval);
        };
    }

    const getAddFees = async (unstaketime, value, index) => {
        const currentEpoch = Math.floor(Date.now() / 1000);
        let remainingTime = parseInt(unstaketime) - currentEpoch;
        if (remainingTime < 12 * 3600) remainingTime = 12 * 3600;

        const calculateAddFee = (val) => {
            const fee = parseFloat(val) * ((parseFloat(data?.config?.fee_rate) / parseFloat(data?.config?.unstakeSeconds)) * remainingTime / 100);
            return parseFloat(fee).toFixed(4);
        };

        setAddFees((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = parseFloat(calculateAddFee(value));
            return newVal;
        });

        const interval = setInterval(() => {
            setAddFees((prevVal) => {
                const newVal = [...prevVal];
                newVal[index] = parseFloat(calculateAddFee(value));
                return newVal;
            });
        }, 300000);

        return () => {
            clearInterval(interval);
        };
    };

    const handleInputChangeAdd = async (event) => {
        const { value } = event.target;
        setCustomAddInput(value);
        getCustomAddFees(userStaked.unstakeTime, value);
    }

    const handleInputChange = async (event) => {
        const { value } = event.target;
        const inputValue = parseInt(value, 10);

        clearTimeout(errorTimeout.current);
        let timeoutOccurred = false;
        errorTimeout.current = setTimeout(() => {
            timeoutOccurred = true;
            const checkMin = inputValue < (parseFloat(data?.config?.min_days) || 1);
            const checkMod = inputValue % 3 !== 0;

            if (checkMin || checkMod) {
                if (checkMin) {
                    FunctionStore.handleError(`Cannot be less than ${data?.config?.min_days || 1} day`);
                }
                if (checkMod && typeof (userStaked) != 'undefined' && userStaked != null) {
                    FunctionStore.handleError('Days must be a multiple of 3');
                    const nextMultiple = Math.ceil(inputValue / 3) * 3;
                    setDays(nextMultiple);
                }
            }
        }, 2000);

        setDays(value);
        if (!timeoutOccurred) {
            if (alreadyStakedwax) {
                const fee_obj = await getRenewFees(parseFloat(userStaked?.stakedcpu));
                fee_obj.discounted *= value;
                fee_obj.fee *= value;
                fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                setRenewFees(fee_obj.discounted);
                setRenewFeesOrg(fee_obj.fee);
            } else {
                const fees = (parseFloat(data?.config?.fee_rate) * stake * value) / 100;
                setCustom(fees);
            }
        }
    };




    const handleInputChangeStake = async (event) => {
        const { value } = event.target;
        setStake(value);
        var fees = (parseFloat(data?.config?.fee_rate) * value * days) / 100;
        setCustom(fees);
    };
    const handleInputChangeWallet = (event) => {
        const { value } = event.target;
        setWallet(value);
        clearTimeout(searchTimeout.current);
        searchTimeout.current = setTimeout(() => {
            performSearch(value);
        }, 1200);
    };

    const performSearch = async (inputValue) => {
        if (inputValue !== '') {
            setDays(data?.config?.min_days);
            var boolcheck = await FunctionStore.checkAccountExists(inputValue);
            if (boolcheck) {
                const cpu = await FunctionStore.getAccountCpuUsage(inputValue);
                const { alreadyStaked, userData } = await FunctionStore.checkAlreadyStaked(inputValue);
                if (alreadyStaked) {
                    setUserStaked(userData);
                    setAlreadyStaked(true);
                    setExpiryStamp(userData.unstakeTime);
                    var fee_obj = await getRenewFees(parseFloat(userData.stakedcpu));
                    fee_obj.discounted *= data?.config?.renew_mult ?? 1;
                    fee_obj.fee *= data?.config?.renew_mult ?? 1;
                    fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                    fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                    setRenewFees(fee_obj.discounted);
                    setRenewFeesOrg(fee_obj.fee);
                    getAddFees(parseFloat(userData.unstakeTime), 100, 0);
                    getAddFees(parseFloat(userData.unstakeTime), 200, 1);
                    getAddFees(parseFloat(userData.unstakeTime), 500, 2);
                    getAddFees(parseFloat(userData.unstakeTime), 1000, 3);
                }
                else {
                    setUserStaked(null);
                    setAlreadyStaked(false);
                    setExpiryStamp(0);
                    setAddFees([0, 0, 0, 0]);
                    setCustomAddInput(0);
                    setCustomAddFee(0);
                    setRenewFees(0);
                    setRenewFeesOrg(0);
                }
                setCpuAvb(cpu.cpuUsed);
                setCpuMax(cpu.cpuMax);
                setValid(true);
                AccountStore.setWallet(inputValue);
            } else {
                FunctionStore.handleError('Invalid Account Name');
                setUserStaked(null);
                setExpiryStamp(0);
                data.user.cpuUsed = 0;
                data.user.cpuMax = 0;
                setCpuAvb(0);
                setCpuMax(0);
                setAlreadyStaked(false);
                setAddFees([0, 0, 0, 0]);
                setCustomAddInput(0);
                setCustomAddFee(0);
                setRenewFees(0);
                setRenewFeesOrg(0);
            }
        } else {
            setUserStaked(null);
            setExpiryStamp(0);
            data.user.cpuUsed = 0;
            data.user.cpuMax = 0;
            setCpuAvb(0);
            setCpuMax(0);
            setAlreadyStaked(false);
            setAddFees([0, 0, 0, 0]);
            setCustomAddInput(0);
            setCustomAddFee(0);
            setRenewFees(0);
            setRenewFeesOrg(0);
            setDays(data?.config?.min_days);
        }
    };


    const getRenewFees = async (value) => {
        var fee = parseFloat(data?.config?.fee_rate) / 100;
        fee = value * fee;
        var discounted = fee - (fee * parseFloat(data?.config.renew_dis) / 100);
        fee = parseFloat(fee);
        discounted = parseFloat(discounted);
        return { fee, discounted };
    }

    if (AccountStore.get_loading() || data == null)
        return <Preloader />

    return (
        AccountStore.get_loading() ? <Preloader /> :
            <ContentBox>
                <div className="menu-child">
                    <div className="menu-children child1">
                        <div className="child1 subchild">
                            <span style={{ fontWeight: "bold", fontSize: '1.2em' }}>Current Fees</span>
                            <span style={{ color: "#ffde59", fontSize: '1.5em' }}>{`${parseFloat(data?.config?.fee_rate).toFixed(3) ?? ''}%/day`}</span>
                            <span>{`loan length ${data?.config?.min_days ?? '1'}-${data?.config?.max_days ?? '45'} days`}</span>
                        </div>
                        <div className="child1 subchild">
                            <span style={{ fontWeight: "bold", fontSize: '1.2em' }}>Current Available Wax</span>
                            <span style={{ color: "#ffde59", fontSize: '1.5em' }}>{` ${parseFloat(data?.config?.balance).toFixed(2) ?? '0.00'} WAX`}</span>
                        </div>
                        <div className="child1 subchild">
                            <div className="subchild subchild2">
                                <span style={{ fontWeight: "bold", fontSize: '1.2em', whiteSpace: 'nowrap' }}>New Funds</span>
                                <span style={{ color: "#ffde59", fontSize: '1.2em', whiteSpace: 'nowrap' }}>{`${parseFloat(data?.config?.refund?.bal ?? 0).toFixed(2)} WAX`}</span>
                            </div>
                            <div className="subchild subchild2">
                                <span className="subchild3">Avb In</span>
                                <span style={{ color: "#ffde59" }}>{data?.config?.refund?.expiry == 0 ? "00:00:00" : <Mytimer expiryTimestamp={data?.config?.refund?.expiry ?? 0} />}</span>
                            </div>
                        </div>
                    </div>
                    <button className="logbutton" style={{ width: "auto", minHeight: '2.5em' }} onClick={() => { setMultipleMode(multiple ? false : true); }}>
                        {multiple ? 'Single Wallet Mode' : 'Multiple Wallet Staking'}
                    </button>
                    {!multiple ? <><div className="menu-children child2">
                        <div className="child2 title">
                            <span>CPU Receiver : </span>
                            <input
                                className='title inputboxchild'
                                type="text"
                                placeholder="Enter Wallet Address"
                                value={wallet}
                                onChange={(event) => handleInputChangeWallet(event)}

                            />
                        </div>
                        <div className="child2 child3">
                            <span>{`CPU Usage : ${parseFloat(cpuAvb).toFixed(2)}ms/${parseFloat(cpuMax).toFixed(2)}ms`}&nbsp;</span>
                            <div className="cpu">
                                <CircularProgressbar
                                    value={((cpuAvb ?? 0) / (cpuMax ?? 0)) * 100}
                                    text={cpuAvb && cpuMax ? `${parseFloat((cpuAvb / cpuMax) * 100).toFixed(0)}%` : '0%'}
                                    styles={buildStyles({
                                        rotation: 0.25,
                                        strokeLinecap: 'butt',
                                        textSize: '2em',
                                        pathTransitionDuration: 0.5,
                                        pathColor: `rgba(62, 152, 199, ${cpuAvb && cpuMax ? `${parseFloat((cpuAvb / cpuMax) * 100)}` : 0})`,
                                        textColor: parseFloat((cpuAvb / cpuMax) * 100).toFixed(0) >= 90 ? 'red' : '#ffffff',
                                        trailColor: parseFloat((cpuAvb / cpuMax) * 100).toFixed(0) >= 90 ? 'red' : '#ffffff',
                                        backgroundColor: '#0a3267',
                                    })}
                                />
                            </div>
                        </div>
                        {alreadyStakedwax ? <>
                            <span>Currently Staked : {parseFloat(userStaked?.stakedcpu).toFixed(2)}&nbsp;WAX</span>
                            <span>Current Staking Period Ends In :</span>
                            {timerVisible && (
                                <Mytimer expiryTimestamp={expiryStamp} type={'a'} color={'#ff2400'} />
                            )}
                            <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', gap: '.5em' }}>
                                <div className="child2 child4">
                                    <div className="child4 subchild4">
                                        <span>Add</span>
                                        <input
                                            className='inputbox'
                                            type="number"
                                            pattern="[0-9]*"
                                            value={customaddInput ?? 0}
                                            onChange={(event) => handleInputChangeAdd(event)}
                                        />
                                        <span>WAX</span>
                                    </div>
                                    <button
                                        onClick={async () => {
                                            if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                            if (customAddFee > 0 && customAddFee >= parseFloat(data?.config?.min_amount)) {
                                                await FunctionStore.stakeWax('add', [{
                                                    account: wallet,
                                                    quantity: customAddFee,
                                                    days: 0
                                                }]);
                                            }
                                            else FunctionStore.handleError('Invalid Fees');
                                        }}>{customAddFee?.toPrecision(2) ?? 0}&nbsp;WAX</button>
                                </div>
                                <div className="child2 child4">
                                    <div className="child4 subchild4">
                                        <span>Renew</span>
                                        <input
                                            className='inputbox'
                                            type="number"
                                            pattern="[0-9]*"
                                            value={days}
                                            onChange={(event) => handleInputChange(event)}
                                        />
                                        <span>DAYS</span>
                                    </div>
                                    <button
                                        onClick={async () => {
                                            if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                            var checkPos = renewfees > 0;
                                            var checkValid = renewfees > parseFloat(data?.config?.min_days);
                                            var checkMod = days % 3 == 0;
                                            if (checkPos && checkValid && checkMod) {
                                                await FunctionStore.stakeWax('renew', [{
                                                    account: wallet,
                                                    quantity: renewfees,
                                                    days: days
                                                }]);
                                            }
                                            else {
                                                if (!checkValid) FunctionStore.handleError('Fees Must be Positive');
                                                else if (!checkMod) FunctionStore.handleError('Days Should be Multiple of 3')
                                                else FunctionStore.handleError('Fees must be greater than min fees');
                                            }
                                        }}><span style={{ color: 'white', opacity: '0.6', textDecoration: 'line-through' }}>{renewfeesOrg ? (renewfeesOrg).toPrecision(3) : ''}</span>&nbsp;{renewfees ? (renewfees).toPrecision(3) : 0} WAX</button>
                                </div></div>
                        </> : <div className="child2 child4">
                            <div className="child4 subchild4">
                                <input
                                    className='inputbox'
                                    type="number"
                                    pattern="[0-9]*"
                                    value={stake}
                                    onChange={(event) => handleInputChangeStake(event)}
                                />
                                <span>STAKED FOR</span>
                                <input
                                    className='inputbox'
                                    type="number"
                                    pattern="[0-9]*"
                                    value={days}
                                    onChange={(event) => handleInputChange(event)}
                                />
                                <span>DAYS</span>
                            </div>
                            <button onClick={async () => {
                                if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                validAcc && customstake != 0 && customstake >= parseFloat(data?.config?.min_amount ?? 0) && days >= (data?.config?.min_days ?? 1) ? await FunctionStore.stakeWax('new', [{
                                    account: wallet,
                                    quantity: customstake,
                                    days: days
                                }]) : days < (data?.config?.min_days ?? 1) ? FunctionStore.handleError(`Must be greater than or equal to min days : ${data?.config?.min_days ?? 1}`) : FunctionStore.handleError('Invalid Account or Stake Amount');
                            }}>
                                {`${customstake.toFixed(3)} WAX`}
                            </button>
                        </div>}
                        <div className="child2 child5">
                            <div className="child3 column-center">
                                <span>{alreadyStakedwax ? '+' : ''}100 WAX</span>
                                {alreadyStakedwax ? '' : <span style={{ fontSize: '.8em' }}>{`staked for ${days} days`}</span>}
                                <button onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                    if (alreadyStakedwax) {
                                        if (addfees[0] > 0 && addfees[0] >= parseFloat(data?.config?.min_amount)) {
                                            await FunctionStore.stakeWax('add', [{
                                                account: wallet,
                                                quantity: addfees[0],
                                                days: 0
                                            }]);

                                        }
                                        else FunctionStore.handleError('Invalid Request');
                                    }
                                    else {
                                        validAcc ? FunctionStore.stakeWax('new', [{
                                            account: wallet,
                                            quantity: ((data?.config?.fee_rate * 100) / 100) * days,
                                            days: days
                                        }]) : FunctionStore.handleError("Invalid Request");
                                    }
                                }}>{alreadyStakedwax ? `ADD FOR ${addfees[0].toPrecision(3) ?? 0} WAX` : `BUY FOR ${getAmount(100).toFixed(3)} WAX`}</button>
                            </div>
                            <div className="child3 column-center">
                                <span>{alreadyStakedwax ? '+' : ''}200 WAX</span>
                                {alreadyStakedwax ? '' : <span style={{ fontSize: '.8em' }}>{`staked for ${days} days`}</span>}
                                <button onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                    if (alreadyStakedwax) {
                                        if (addfees[1] > 0 && addfees[1] >= parseFloat(data?.config?.min_amount)) {
                                            await FunctionStore.stakeWax('add', [{
                                                account: wallet,
                                                quantity: addfees[1],
                                                days: 0
                                            }]);

                                        }
                                        else FunctionStore.handleError('Invalid Request');
                                    }
                                    else {
                                        validAcc ? FunctionStore.stakeWax('new', [{
                                            account: wallet,
                                            quantity: ((data?.config?.fee_rate * 200) / 100) * days,
                                            days: days
                                        }]) : FunctionStore.handleError("Invalid Request");
                                    }
                                }}>{alreadyStakedwax ? `ADD FOR ${addfees[1].toPrecision(3) ?? 0} WAX` : `BUY FOR ${getAmount(200).toFixed(3)} WAX`}</button>
                            </div>
                            <div className="child3 column-center">
                                <span>{alreadyStakedwax ? '+' : ''}500 WAX</span>
                                {alreadyStakedwax ? '' : <span style={{ fontSize: '.8em' }}>{`staked for ${days} days`}</span>}
                                <button onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                    if (alreadyStakedwax) {
                                        if (addfees[2] > 0 && addfees[2] >= parseFloat(data?.config?.min_amount)) {
                                            await FunctionStore.stakeWax('add', [{
                                                account: wallet,
                                                quantity: addfees[2],
                                                days: 0
                                            }]);

                                        }
                                        else FunctionStore.handleError('Invalid Request');
                                    }
                                    else {
                                        validAcc ? FunctionStore.stakeWax('new', [{
                                            account: wallet,
                                            quantity: ((data?.config?.fee_rate * 500) / 100) * days,
                                            days: days
                                        }]) : FunctionStore.handleError("Invalid Request");
                                    }
                                }}>{alreadyStakedwax ? `ADD FOR ${addfees[2].toPrecision(3) ?? 0} WAX` : `BUY FOR ${getAmount(500).toFixed(3)} WAX`}</button>
                            </div>
                            <div className="child3 column-center">
                                <span>{alreadyStakedwax ? '+' : ''}1000 WAX</span>
                                {alreadyStakedwax ? '' : <span style={{ fontSize: '.8em' }}>{`staked for ${days} days`}</span>}
                                <button onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); login(); return; }
                                    if (alreadyStakedwax) {
                                        if (addfees[3] > 0 && addfees[3] >= parseFloat(data?.config?.min_amount)) {
                                            await FunctionStore.stakeWax('add', [{
                                                account: wallet,
                                                quantity: addfees[3],
                                                days: 0
                                            }]);

                                        }
                                        else FunctionStore.handleError('Invalid Request');
                                    }
                                    else {
                                        validAcc ? FunctionStore.stakeWax('new', [{
                                            account: wallet,
                                            quantity: ((data?.config?.fee_rate * 1000) / 100) * days,
                                            days: days
                                        }]) : FunctionStore.handleError("Invalid Request");
                                    }
                                }}>{alreadyStakedwax ? `ADD FOR ${addfees[3].toPrecision(3) ?? 0} WAX` : `BUY FOR ${getAmount(1000).toFixed(3)} WAX`}</button>
                            </div>
                        </div>
                    </div>
                        {data?.user?.length > 0 ? <div className="parent-div">
                            <span className="title3">List of Current Stakes</span>
                            <Table stakingData={data?.user ?? []} config={data?.config ?? []}></Table>
                        </div> : ''}</> :
                        <div className="parent-div"  >
                            <span className="title3"  >Multiple Wallet Stake</span>
                            <MultipleTable config={data?.config ?? []} />
                        </div>
                    }
                </div>
            </ContentBox>
    )
}
export default observer(Menu)