import {
    makeAutoObservable,
    toJS
} from "mobx";
import AccountStore from "./accountStore";
import * as waxjs from "@waxio/waxjs/dist";
import AnchorLinkBrowserTransport from "anchor-link-browser-transport";
import AnchorLink from "anchor-link";
import {
    toast
} from "react-toastify";

class functionstore {
    constructor() {
        makeAutoObservable(this);
        this.fetchAllData();
    }
    endpoint = "https://wax.eosusa.io";
    wax = new waxjs.WaxJS(this.endpoint, null, null, false);
    transport = new AnchorLinkBrowserTransport();
    anchorLink = new AnchorLink({
        transport: this.transport,
        chains: [{
            chainId: "1064487b3cd1a897ce03ae5b6a865651747e2e152090f99c1d19d44e01aea5a4",
            nodeUrl: this.endpoint,
        },],
    });
    dapp = "Loan Pool";
    contract = "cpuloanpoolz";
    path = "/v1/chain/get_table_rows";
    eosjsName = require("eosjs-account-name");
    accountAddressUint64 = "";


    handleError(e) {
        AccountStore.set_loading(false);
        toast.error(
            e.message ?
                e.message.includes("undefined") ?
                    "Failed to fetch!" :
                    e.message :
                e, {
            autoClose: 10000
        }
        );
        console.log(e)
    }

    handleSuccess(message) {
        toast.success(message);
    }

    async Login(data) {
        AccountStore.setTableData(data);
        AccountStore.accountAddress = data.accountName;
        this.accountAddressUint64 = this.eosjsName.nameToUint64(AccountStore.accountAddress).toString();
        await this.Refresh();
    }

    async Refresh() {
        try {
            AccountStore.balance = await this.getBalance(AccountStore.accountAddress);;
            var userData = AccountStore.getUserData();
            userData.config = userData.config ? userData.config : await this.getConfig();
            userData.user = await this.getUser(AccountStore.accountAddress);
            AccountStore.setUserData(userData);
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async sendTransaction(actions) {
        AccountStore.set_loading(true);
        const ualSession = AccountStore.getTableData();
        return await ualSession.signTransaction({
            actions: actions,
        }, {
            blocksBehind: 4,
            expireSeconds: 3600,
        });
    }

    async getBalance(account) {
        try {
            var data = JSON.stringify({
                json: true,
                code: 'eosio.token',
                scope: account,
                table: "accounts",
                limit: 1000,
            });

            const response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST",
            });
            const body = await response.json();
            if (body.rows.length > 0) {
                for (const d of body.rows) {
                    if (d.balance.includes('WAX')) return parseFloat(d.balance).toFixed(4) + " WAX";
                    else return "0.0000 WAX";
                }
            }
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getRefunds() {
        try {
            var timeStamp;
            const pool_response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify({
                    json: true,
                    code: this.contract,
                    scope: this.contract,
                    table: "pools",
                    limit: 1000,
                }),
                method: "POST",
            });
            const pool_body = await pool_response.json();
            var pool_refunds = [];
            if (pool_body.rows.length > 0) {
                var pools = pool_body.rows[0].pools;
                for (const pool of pools) {
                    var response = await fetch(this.endpoint + this.path, {
                        headers: {
                            "Content-Type": "text/plain"
                        },
                        body: JSON.stringify({
                            json: true,
                            code: "eosio",
                            scope: pool.pool,
                            table: "refunds",
                            limit: 1000,
                        }),
                        method: "POST",
                    });
                    const refund_body = await response.json();
                    console.log(refund_body)
                    if (refund_body.rows.length > 0) {
                        for (const d of refund_body.rows) {
                            const eosioDate = new Date(d.request_time + 'Z');
                            const unixEpochTimeMillis = eosioDate.getTime();
                            const unixEpochTimeSeconds = Math.floor(unixEpochTimeMillis / 1000);
                            pool_refunds.push({
                                pool: d.owner,
                                request_time: unixEpochTimeSeconds + (86400 * 3),
                                amount: d.cpu_amount
                            });
                        }
                    }
                }
            }
            var bal = 0;
            var expiry = 0;
            if (pool_refunds.length > 0) {
                const currentUnixEpochTimeSeconds = Math.floor(Date.now() / 1000);
                const nearestRefund = pool_refunds.reduce((nearest, refund) => {
                    const timeDifference = Math.abs(currentUnixEpochTimeSeconds - refund.request_time);
                    if (timeDifference < nearest.timeDifference) {
                        return {
                            timeDifference,
                            refund
                        };
                    }
                    return nearest;
                }, { timeDifference: Infinity, refund: null });
                expiry = nearestRefund.refund.request_time;
                bal = nearestRefund.refund.amount;
            }
            return { bal: bal, expiry: expiry };
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getConfig() {
        try {
            var data = JSON.stringify({
                json: true,
                code: this.contract,
                scope: this.contract,
                table: "config",
                limit: 1000,
            });

            const response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST",
            });
            const body = await response.json();
            var result;
            if (body.rows.length > 0) {
                result = body.rows[0];
                var balance = await this.getBalance(this.contract);
                var refund = await this.getRefunds();
                return { ...result, balance: balance, refund: refund };
            }
            else return [];
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async getAccountCpuUsage(accountName) {
        try {
            const data = {
                account_name: accountName,
            };
            const response = await fetch(this.endpoint + '/v1/chain/get_account', {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify(data),
                method: "POST",
            });
            const body = await response.json();
            const cpuLimit = body.cpu_limit;
            const cpuUsed = cpuLimit.used / 1000;
            const cpuMax = cpuLimit.max / 1000;
            return { cpuUsed, cpuMax };
        } catch (e) {
            console.log(e);
        }
    }

    async checkAccountExists(accountName) {
        try {
            const data = {
                account_name: accountName,
            };
            const response = await fetch(this.endpoint + '/v1/chain/get_account', {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: JSON.stringify(data),
                method: "POST",
            });
            const body = await response.json();
            if (body.hasOwnProperty("account_name")) return true;
            else return false;
        } catch (e) {
            this.handleError(e.message);
            console.log(e)
            return false;
        }
    }

    async stakeWax(type, data) {
        try {
            var spliceNo = type == "new" ? 15 : type == "add" ? 20 : 25;
            if (data.length > spliceNo) {
                const batches = Math.ceil(data.length / spliceNo);
                for (let i = 0; i < batches; i++) {
                    const batchData = data.slice(i * spliceNo, (i + 1) * spliceNo);
                    await this.processBatch(type, batchData);
                }
            } else {
                await this.processBatch(type, data);
            }
        } catch (e) {
            this.handleError(e.message);
            console.log(e);
        }
    }

    async processBatch(type, batchData) {
        var action = [];
        for (const d of batchData) {
            var memo =
                type === "new"
                    ? "new%" + d.days + "%" + d.account
                    : type === "add"
                        ? "add%" + d.account
                        : "renew%" + d.days + "%" + d.account;
            action.push({
                account: "eosio.token",
                name: "transfer",
                authorization: [
                    {
                        actor: AccountStore.accountAddress,
                        permission: "active",
                    },
                ],
                data: {
                    from: AccountStore.accountAddress,
                    to: this.contract,
                    quantity: parseFloat(d.quantity).toFixed(8) + " WAX",
                    memo: memo,
                },
            });
        }
        const result = await this.sendTransaction(action);
        setTimeout(async () => {
            this.handleSuccess("Transaction Successful");
            await this.Refresh();
            AccountStore.set_loading(false);
        }, 10);
    }

    async getUser(user) {
        try {
            var data = JSON.stringify({
                json: true,
                code: this.contract,
                scope: this.contract,
                table: "staking",
                limit: 1000,
                key_type: `i64`,
                index_position: 3,
                lower_bound: this.eosjsName.nameToUint64(AccountStore.accountAddress).toString(),
                upper_bound: this.eosjsName.nameToUint64(AccountStore.accountAddress).toString(),
            });

            const response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain",
                },
                body: data,
                method: "POST",
            });

            if (!response.ok) {
                throw new Error(`Request failed with status ${response.status}`);
            }

            const text = await response.text();
            let body;
            try {
                body = JSON.parse(text);
            } catch (error) {
                throw new Error(`Failed to parse JSON: ${text}`);
            }

            var result = [];
            if (body.rows && body.rows.length > 0) {
                for (const d of body.rows) {
                    result.push(d);
                }
            }
            return result;
        }
        catch (e) {
            this.handleError(e.message);
            console.log(e)
        }
    }

    async checkAlreadyStaked(user) {
        try {
            var data = JSON.stringify({
                json: true,
                code: this.contract,
                scope: this.contract,
                table: "staking",
                limit: 1000,
                lower_bound: user,
                upper_bound: user,
            });

            const response = await fetch(this.endpoint + this.path, {
                headers: {
                    "Content-Type": "text/plain"
                },
                body: data,
                method: "POST",
            });

            if (response.ok) {
                const body = await response.json();
                var alreadyStaked = false;
                var userData = [];
                if (body.rows.length > 0) {
                    alreadyStaked = true;
                    userData = body.rows[0];
                }
                return { alreadyStaked, userData };
            } else {
                console.log(user);
                throw new Error('Request failed with status ' + response.status);
            }
        } catch (e) {
            this.handleError(e.message);
            console.log(e);
        }
    }


    async fetchAllData() {
        AccountStore.set_loading(true);
        var data = {
            config: await this.getConfig(),
            user: [],
            cpu: []
        }
        AccountStore.setUserData(data);
        AccountStore.set_loading(false);
    }
}

export default new functionstore();
