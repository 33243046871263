import React, { useState, useEffect, useRef } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import functionstore from '../../store/functionstore';
import './index.sass';
import Mytimer from '../timer/index';
import Menu from '../../pages/Menu/index';
import AccountStore from '../../store/accountStore';
import FunctionStore from '../../store/functionstore';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
export default function BasicTable(props) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [tableData, setTableData] = useState([]);
    const [val, setVal] = useState([]);
    const [addfee, setAddFee] = useState([]);
    const [renew, setRenew] = useState([]);
    const [renewfee, setRenewFee] = useState([]);
    const [renewOrg, setRenewOrg] = useState([]);
    const [config, setConfig] = useState(null);
    const [day, setDays] = useState([]);
    const [totalNewFees, setTotalNewFees] = useState(0);
    const [totalRenewFees, setTotalRenewFees] = useState(0);
    var errorTimeout = useRef(null);

    useEffect(() => {
        const calculateTotalFees = () => {
            const sum = addfee.reduce((total, value) => total + (value??0), 0);
            setTotalNewFees(!isNaN(sum) ? parseFloat(sum) : 0.00);
        };

        calculateTotalFees();
    }, [addfee]);

    useEffect(() => {
        return () => {
            clearTimeout(errorTimeout.current);
        };
    }, []);

    useEffect(() => {
        const calculateTotalRenewFees = () => {
            const sum = renewfee.reduce((total, value) => total + (value??0), 0);
            setTotalRenewFees(!isNaN(sum) ? parseFloat(sum) : 0.00);
        };

        calculateTotalRenewFees();
    }, [renewfee]);

    useEffect(() => {
        if (props.config.length != 0) {
            setConfig(props.config);
        }
    }, [props.config]);

    useEffect(() => {
        const getTableData = async () => {
            if (props.stakingData.length > 25) {
                const startIndex = page * rowsPerPage;
                const endIndex = startIndex + rowsPerPage;
                const slicedData = props.stakingData.slice(startIndex, endIndex);
                const cpuPromises = slicedData.map((data) => functionstore.getAccountCpuUsage(data.account));
                const cpuResults = await Promise.all(cpuPromises);
                const tableData = await Promise.all(
                    slicedData.map(async (data, index) => {
                        const remainingTime = await getTimeRemaining(data.unstakeTime);
                        const fee_obj = await getRenewFees(parseFloat(data.stakedcpu));
                        fee_obj.discounted *= config?.renew_mult ?? 1;
                        fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                        fee_obj.fee *= config?.renew_mult ?? 1;
                        fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                        setRenewFee((prevVal) => {
                            const newVal = [...prevVal];
                            newVal[index] = fee_obj.discounted;
                            return newVal;
                        });
                        setRenewOrg((prevVal) => {
                            const newVal = [...prevVal];
                            newVal[index] = fee_obj.fee;
                            return newVal;
                        });
                        return createData(
                            data.account,
                            cpuResults[index].cpuMax,
                            cpuResults[index].cpuUsed,
                            data.stakedcpu,
                            remainingTime
                        );
                    })
                );
                setTableData(tableData);
            } else {
                const cpuPromises = props.stakingData.map((data) => functionstore.getAccountCpuUsage(data.account));
                const cpuResults = await Promise.all(cpuPromises);
                const tableData = await Promise.all(
                    props.stakingData.map(async (data, index) => {
                        const remainingTime = await getTimeRemaining(data.unstakeTime);
                        const fee_obj = await getRenewFees(parseFloat(data.stakedcpu));
                        fee_obj.discounted *= config?.renew_mult ?? 1;
                        fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                        fee_obj.fee *= config?.renew_mult ?? 1;
                        fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                        setRenewFee((prevVal) => {
                            const newVal = [...prevVal];
                            newVal[index] = fee_obj.discounted;
                            return newVal;
                        });
                        setRenewOrg((prevVal) => {
                            const newVal = [...prevVal];
                            newVal[index] = fee_obj.fee;
                            return newVal;
                        });
                        return createData(
                            data.account,
                            cpuResults[index].cpuMax,
                            cpuResults[index].cpuUsed,
                            data.stakedcpu,
                            remainingTime
                        );
                    })
                );
                setTableData(tableData);
            }
        };

        const getTimeRemaining = async (TimeString) => {
            const SECONDS_PER_DAY = 24 * 60 * 60;
            const SECONDS_PER_HOUR = 60 * 60;
            const currentEpochTime = Math.floor(Date.now() / 1000);
            const givenEpoch = parseInt(TimeString);
            var difference = givenEpoch - currentEpochTime;
            const days = Math.floor(difference / SECONDS_PER_DAY);
            const hours = Math.floor((difference % SECONDS_PER_DAY) / SECONDS_PER_HOUR);
            return <Mytimer expiryTimestamp={TimeString} type={'y'} color={'white'} />;
        };

        const createData = async (account, cpuMax, cpuUsed, staked, remainingTime) => {
            return {
                account,
                cpuMax: cpuMax.toFixed(2),
                cpuUsed: cpuUsed.toFixed(2),
                staked: parseFloat(staked),
                expiry: remainingTime,
            };
        };

        getTableData();
    }, [page, rowsPerPage, props.stakingData]);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleInputChange = async (event, index) => {
        const { value } = event.target;
        if (value < 0)
            functionstore.handleError(`Value cannot be negative`);

        setVal((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = value;
            return newVal;
        });
        await getAddFees(props.stakingData[index].unstakeTime, index, value);
    };

    const handleInputChangeDay = async (event, index) => {
        const { value } = event.target;
        if(value > 0){
            clearTimeout(errorTimeout.current);
            let timeoutOccurred = false;
            const inputValue = parseInt(value, 10);
    
            errorTimeout.current = setTimeout(async () => {
                timeoutOccurred = true;
                if (value % 3 !== 0) {
                    functionstore.handleError('Days must be a multiple of 3');
                    const nextMultiple = Math.ceil(inputValue / 3) * 3;
                    setDays((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = nextMultiple;
                        return newVal;
                    });
                    const fee_obj = await getRenewFees(parseFloat(tableData[index].staked));
                    fee_obj.discounted *= nextMultiple;
                    fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                    fee_obj.fee *= nextMultiple;
                    fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                    setRenewFee((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = fee_obj.discounted;
                        return newVal;
                    });
                    setRenewOrg((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = fee_obj.fee;
                        return newVal;
                    });
                }
                if (value < (parseFloat(config?.renew_mult))) {
                    functionstore.handleError(`Cannot be less than ${config?.renew_mult} days`);
                }
            }, 2000);
    
            setDays((prevVal) => {
                const newVal = [...prevVal];
                newVal[index] = value;
                return newVal;
            });
    
            if (!timeoutOccurred) {
                if (value !== '') {
                    const fee_obj = await getRenewFees(parseFloat(tableData[index].staked));
                    fee_obj.discounted *= value;
                    fee_obj.discounted = Math.max(fee_obj.discounted.toFixed(3), fee_obj.discounted);
                    fee_obj.fee *= value;
                    fee_obj.fee = Math.max(fee_obj.fee.toFixed(3), fee_obj.fee);
                    setRenewFee((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = fee_obj.discounted
                        return newVal;
                    });
                    setRenewOrg((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = fee_obj.fee;
                        return newVal;
                    });
                } else {
                    setRenewFee((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = config?.renew_mult;
                        return newVal;
                    });
                    setRenewOrg((prevVal) => {
                        const newVal = [...prevVal];
                        newVal[index] = config?.renew_mult;
                        return newVal;
                    });
                }
            }
        }
    };

    const getAddFees = async (unstaketime, index, value) => {
        const currentEpoch = Math.floor(Date.now() / 1000);
        let remainingTime = parseInt(unstaketime) - currentEpoch;
        if (remainingTime < 12 * 3600) remainingTime = 12 * 3600;

        const calculateAddFee = (val) => {
            const fee = parseFloat(val) * ((parseFloat(config?.fee_rate) / parseFloat(config?.unstakeSeconds)) * remainingTime / 100);
            return parseFloat(fee);
        };
        setAddFee((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = calculateAddFee(value);
            return newVal;
        });

        const interval = setInterval(() => {
            setAddFee((prevVal) => {
                const newVal = [...prevVal];
                newVal[index] = calculateAddFee(value);
                return newVal;
            });
        }, 300000);

        return () => {
            clearInterval(interval);
        };
    };

    const getRenewFees = async (value) => {
        var fee = parseFloat(config?.fee_rate) / 100;
        fee = value * fee;
        var discounted = fee - (fee * parseFloat(config?.renew_dis) / 100);
        fee = parseFloat(fee);
        discounted = parseFloat(discounted);
        return { fee, discounted };
    }


    const totalRows = tableData.length;

    const submit = async (type, arr_wallets) => { 
        const temp_msg = type == "add" ? "Given that the number of wallets exceeds 20, transactions will be sent in batches of 20" : "Given that the number of wallets exceeds 25, transactions will be sent in batches of 25";
        confirmAlert({
            title: 'Please confirm to continue',
            message: temp_msg,
            buttons: [
                {
                    label: 'Yes',
                    onClick: async () => await functionstore.stakeWax(type, arr_wallets)
                },
                {
                    label: 'No',
                    onClick: () => FunctionStore.handleError("Transaction Cancelled !")
                }
            ]
        });
    };

    const handleRemoveRow = (index) => {
        setDays((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = 0;
            return newVal;
        });
        setRenewFee((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = 0;
            return newVal;
        });
        setRenewOrg((prevVal) => {
            const newVal = [...prevVal];
            newVal[index] = 0;
            return newVal;
        });
    };

    return (
        <TableContainer className="table" component={Paper}
            style={{
                backgroundColor: 'transparent',
                marginTop: '1.5em',
                padding: '0.5em',
                border: '0px solid #56dcd7',
                paddingBottom: '5em'
            }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow
                        style={{
                            borderTop: 'none',
                            borderLeft: 'none',
                            borderRight: 'none',
                            borderBottom: '2px solid #56dcd7'
                        }}

                    >
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto', }} align='center'>
                            ACCOUNT
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                bottom: 0,
                                width: '10%',
                                height: '50%',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRight: '2px solid #ffde59'
                            }}></div>
                        </TableCell>
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                            CPU USAGE
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                bottom: 0,
                                width: '10%',
                                height: '50%',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRight: '2px solid #ffde59'
                            }}></div>
                        </TableCell>
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                            CURRENT STAKED
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                bottom: 0,
                                width: '10%',
                                height: '50%',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRight: '2px solid #ffde59'
                            }}></div>
                        </TableCell>
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                            EXPIRATION
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                bottom: 0,
                                width: '10%',
                                height: '50%',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRight: '2px solid #ffde59'
                            }}></div>
                        </TableCell>
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                            ADD MORE WAX (FEES)
                            <div style={{
                                position: 'absolute',
                                top: '25%',
                                right: 0,
                                bottom: 0,
                                width: '10%',
                                height: '50%',
                                borderTop: 'none',
                                borderLeft: 'none',
                                borderBottom: 'none',
                                borderRight: '2px solid #ffde59'
                            }}></div>
                        </TableCell>
                        <TableCell style={{ position: 'relative', color: '#ffde59', fontFamily: 'Roboto' }} align='center'>
                            RENEW (10% Discount)
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                    </TableRow>
                    {tableData.map((row, index) => (
                        <TableRow key={row.account} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                textDecoration: day[index] == 0 ? 'line-through 5px red' : 'none'
                            }}>
                                {row.account}
                            </TableCell>
                            <TableCell align="center" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                textDecoration: day[index] == 0 ? 'line-through 5px red' : 'none'
                            }}>{`${parseFloat(row.cpuUsed).toFixed(2)}ms/${parseFloat(row.cpuMax).toFixed(2)}ms`}&nbsp;<span style={{ color: parseFloat((row.cpuUsed / row.cpuMax) * 100) >= 90 ? 'red' : 'white' }}>{`${parseFloat((row.cpuUsed / row.cpuMax) * 100).toFixed(2)}%`}</span></TableCell>
                            <TableCell align="center" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                textDecoration: day[index] == 0 ? 'line-through 5px red' : 'none'
                            }}>{`${parseFloat(row.staked).toFixed(3)} WAX`}</TableCell>
                            <TableCell align="center" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                textDecoration: day[index] == 0 ? 'line-through 5px red' : 'none'
                            }}>{row.expiry}</TableCell>
                            <TableCell align="center" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                textDecoration: day[index] == 0 ? 'line-through 5px red' : 'none',
                                padding: '0 0 0 0',
                                borderRight: '2px solid black'
                            }}>
                                <div className='add-container'>
                                    <input
                                        className='inputclass'
                                        type="number"
                                        pattern="[0-9]*"
                                        value={val[index] ?? 0}
                                        onChange={(event) => handleInputChange(event, index)}
                                    />
                                    <span>WAX</span>
                                    <button className='buttonclass'
                                        onClick={async () => {
                                            if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); Menu.login(); return; }
                                            addfee[index] >= parseFloat(config?.min_amount ?? '0.1') ? await functionstore.stakeWax('add', [{
                                                account: row.account,
                                                quantity: addfee[index],
                                                days: day[index]
                                            }]) : functionstore.handleError(`Fees Cannot be less than ${parseFloat(config?.min_amount ?? '0.1')} WAX`);
                                        }}>{parseFloat(addfee[index] ?? 0).toPrecision(3) ?? 0} WAX</button>
                                </div>
                            </TableCell>
                            <TableCell align="center" style={{
                                color: 'white',
                                fontFamily: 'Roboto',
                                border: '1px solid rgba(255,255,255,0.5)',
                                padding: '0 0 0 0',
                                borderLeft: '2px solid black'
                            }}>
                                <div className='add-container'>
                                    <input
                                        className='inputclass'
                                        type="number"
                                        pattern="[0-9]*"
                                        value={day[index] ?? config?.renew_mult ?? 1}
                                        onChange={(event) => handleInputChangeDay(event, index)}
                                    />
                                    <span>Days</span>
                                    <button className='buttonclass'
                                        onClick={async () => {
                                            if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); Menu.login(); return; }
                                            if (renewfee[index] >= parseFloat(config?.min_amount ?? '0.1') && (day[index] ?? config?.renew_mult ?? 1) % 3 == 0)
                                                await functionstore.stakeWax('renew', [{
                                                    account: row.account,
                                                    quantity: renewfee[index],
                                                    days: day[index] ?? config?.renew_mult ?? 1
                                                }]);
                                            else functionstore.handleError('Invalid Request');
                                        }}><span style={{ color: 'white', opacity: '0.6', textDecoration: 'line-through' }}>{renewOrg[index].toPrecision(3) ?? ''}</span>&nbsp;{renewfee[index].toPrecision(3) ?? 0} WAX</button>
                                        <button style={{ backgroundColor: 'transparent', color: 'red', border: 'none', fontSize: '1.2em', border: '1px solid black' }} onClick={() => handleRemoveRow(index)}>X</button>
                                </div>
                            </TableCell>
                        </TableRow>
                    ))}
                    <TableRow>
                        <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ border: 'none', color: 'white', fontFamily: 'Roboto', textAlign: 'center', fontSize: '1em', fontWeight: 'bold', textTransform: 'uppercase' }} colSpan={4}>
                            Total (Fees) for all accounts
                        </TableCell>
                        <TableCell align="center" style={{
                            color: 'white',
                            fontFamily: 'Roboto',
                            border: 'none',
                            padding: '0 0 0 0',
                            backgroundColor: 'transparent',
                        }}>
                            <button className='buttonclass' style={{
                                backgroundColor: '#246aed',
                            }}
                                onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); Menu.login(); return; }
                                    var arr_wallets = [];
                                    tableData.map((row, index) => {
                                        if (addfee[index] >= parseFloat(config?.min_amount ?? '0.1'))
                                            arr_wallets.push({
                                                account: row.account,
                                                quantity: addfee[index],
                                                days: 0
                                            });
                                    });
                                    if (arr_wallets.length > 0) {
                                        arr_wallets.length > 20 ? submit('add', arr_wallets) : await functionstore.stakeWax('add', arr_wallets);
                                    }
                                    else
                                        functionstore.handleError('Invalid Request');
                                }}>{totalNewFees?.toPrecision(3) ?? 0} WAX</button>
                        </TableCell>
                        <TableCell align="center" style={{
                            color: 'white',
                            fontFamily: 'Roboto',
                            border: 'none',
                            padding: '0 0 0 0',
                            backgroundColor: 'transparent',
                        }}>
                            <button className='buttonclass' style={{
                                backgroundColor: '#246aed',
                            }}
                                onClick={async () => {
                                    if (AccountStore.accountAddress == null) { FunctionStore.handleError("Please Login First !"); Menu.login(); return; }
                                    var arr_wallets = [];
                                    tableData.map((row, index) => {
                                        if (renewfee[index] >= parseFloat(config?.min_amount ?? '0.1'))
                                            arr_wallets.push({
                                                account: row.account,
                                                quantity: renewfee[index],
                                                days: day[index] ?? config?.renew_mult ?? 1
                                            });
                                    });
                                    if (arr_wallets.length > 0) {
                                        arr_wallets.length > 25 ? submit('renew', arr_wallets) : await functionstore.stakeWax('renew', arr_wallets);
                                    }
                                    else
                                        functionstore.handleError('Invalid Request');
                                }}>{totalRenewFees.toPrecision(3) ?? 0} WAX</button>
                        </TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{ border: 'none' }} colSpan={5}></TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <TablePagination
                component="div"
                count={props?.stakingData?.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                style={{
                    backgroundColor: 'rgba(255,255,255,.8)',
                    color: 'black',
                    borderRadius: '1em',
                    WebkitBackdropFilter: 'blur(5px)',
                    backdropFilter: 'blur(5px)',
                    width: '100%',
                    fontFamily: 'Roboto',
                    overflow: 'auto',
                    scrollBehavior: 'smooth',
                    scrollbarWidth: 'thin'
                }}
            />
        </TableContainer>
    );
}
