import "./header.sass"
import { useContext, useEffect } from "react";
import StoreContext from "../../store/rootstore";
import "../../pages/Login/index.sass";
import { observer } from "mobx-react";
import { UALContext } from "ual-reactjs-renderer";

function Header() {
    const { AccountStore, FunctionStore } = StoreContext();
    const ual = useContext(UALContext);

    useEffect(() => {
        if (ual.activeUser)
            FunctionStore.Login(ual.activeUser);
    });
    const login = () => {
        ual.logout();
        ual.showModal();
    }
    const logout = () => {
        ual.logout();
        AccountStore.clearAllData();
    }
    const handleClickLog = () => {
        if (AccountStore.accountAddress == null)
            login();
        else
            logout();
    }

    return (
        <div className="header-bar" >
            <div className="row-flex">
                <img className="logo" src={require('../../assets/logo.png')} />
                <div >
                    <span className="tag">Cpu Loan Pool</span>
                    <span className="tagLine" >Rent CPU, Process More, Worry Less!</span>
                </div>
            </div>
            <div className="row-flex">
                {AccountStore.accountAddress != null ?
                    <div className="child">
                        <span style={{ fontFamily: 'Roboto, sans-serif', fontSize: '0.8em', color: 'white' }}>{AccountStore.accountAddress}</span>
                        <span style={{ fontFamily: 'Roboto, sans-serif', fontSize: '.7em', color: '#4fcfb0' }}>{AccountStore.balance}</span>
                    </div>
                    :
                    ''}
                <button className="logbutton"
                    onClick={() => handleClickLog()}>
                    {AccountStore.accountAddress == null ? "Connect Wallet" : "Logout"}
                </button>
            </div>
        </div>
    );
}
export default observer(Header)