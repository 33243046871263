import { useTimer } from 'react-timer-hook';
import { observer } from "mobx-react";
import { useState, useEffect } from 'react';

function MyTimer({ expiryTimestamp,color,type }) {
  const time = new Date();
  const [expiryStamp, setExpiryStamp] = useState(new Date(time.setSeconds(time.getSeconds() + (expiryTimestamp - Math.round(time / 1000)))));
  const {
    days,
    hours,
    minutes,
    seconds
  } = useTimer({ expiryTimestamp: expiryStamp.getTime(), onExpire: () => console.warn('onExpire called ' + expiryStamp) });

  useEffect(() => {
    if (expiryTimestamp !== 0) {
      const newData = new Date();
      const updatedExpiryStamp = new Date(newData.setSeconds(newData.getSeconds() + (expiryTimestamp - Math.round(newData / 1000))));
      setExpiryStamp(updatedExpiryStamp);
    } else {
      setExpiryStamp(null);
    }
  }, [expiryTimestamp]);

  return (
    <div style={{ fontSize: '1em', whiteSpace: 'nowrap', color: `${color ?? 'white'}` }}>
      {typeof(type) != 'undefined' && days == 0 && hours == 0 && minutes == 0 && seconds == 0 ? <span style={{color:'red',fontWeight:'bold'}}>OVERDUE</span> : <>
      {days < 1 ? '' : <span>{days.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        useGrouping: false
      })}{` Day${parseFloat(days) > 1 ? 's' : ''}`}&nbsp;</span>}
      <span>{hours.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}</span>:
      <span>{minutes.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}</span>:
      <span>{seconds.toLocaleString('en-US', {
        minimumIntegerDigits: 2,
        useGrouping: false
      })}&nbsp;hrs</span></>}
    </div>
  );
}

export default observer(MyTimer);
