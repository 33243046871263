import { observer } from "mobx-react";
import { AnimatePresence } from "framer-motion";
import HeaderContainer from "../containers/headerContainer";
import Menu from "../pages/Menu";
import "./index.sass";

function LoanPool() {
return (
        <AnimatePresence mode="wait">
            <HeaderContainer><Menu /></HeaderContainer>
        </AnimatePresence>
    );
}

export default observer(LoanPool);
